"use client";

import { useEffect } from "react";
const LogRocketInitializer = () => {
  const isDevelopment = process.env.NODE_ENV === "development";
  const isLocalTesting = process.env.NEXT_PUBLIC_CLUB_APP_LOGROCKET_LOCAL_TESTING === "true";
  const appId = process.env.NEXT_PUBLIC_CLUB_APP_LOGROCKET_ID;
  useEffect(() => {
    if (!appId) {
      console.error("LogRocket App ID not defined");
      return;
    }
    if (isDevelopment && !isLocalTesting) {
      console.log("LogRocket disabled (dev mode without local testing)");
      return;
    }
    if (isDevelopment && isLocalTesting) {
      console.log("LogRocket enabled (dev mode with local testing)");
    }
    import("logrocket").then(LogRocket => {
      try {
        LogRocket.default.init(appId);
      } catch (error) {
        console.error("Error during LogRocket initialization:", error);
      }
    }).catch(error => {
      console.error("Error loading LogRocket:", error);
    });
  }, [appId, isDevelopment, isLocalTesting]);
  return null;
};
export default LogRocketInitializer;