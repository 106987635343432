"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import dynamic from "next/dynamic";
import { useState } from "react";
const ReactQueryDevtools = dynamic(() => import("@tanstack/react-query-devtools").then(module => module.ReactQueryDevtools), {
  ssr: false
});
export default function QueryProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
        refetchOnWindowFocus: false
      }
    }
  }));
  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="QueryProvider" data-sentry-source-file="QueryProvider.tsx">
      {children}
      {process.env.NODE_ENV === "development" && <ReactQueryDevtools />}
    </QueryClientProvider>;
}